<script setup lang="ts">
import type { User } from "@gasparigit/omnia-sdk";
import "~/assets/css/front.css";
import Tutorial from "~/components/icons/Tutorial.vue";
import { AREAS_MAP } from "~/lib/const";
import { link, userHasRole } from "~/lib/utils/contents";

const omniaSdk = useOmniaSdk();
const route = useRoute();
const preview = usePreviewMode();

const { data: loggedUser, refresh: refreshUser } = await useLoggedUser(
  () => {},
  true,
  function (user: User) {
    if (user.is_first_login) userFirstLoginOpened.value = true;
  },
  "logged-user"
);

const userSettingsOpened = ref(false);
const userFirstLoginOpened = ref(loggedUser.value?.is_first_login);

const { data: userCustomers } = useAsyncData("user-customers", async () => {
  if (!(await omniaSdk.auth.user.token())) return null;

  try {
    return await omniaSdk.auth.user.customers({ admin: true });
  } catch (error) {}
});

const { data: areas } = useAsyncData("all-areas", async () => {
  try {
    return await omniaSdk.v1.areas.list({ pagination: "none" });
  } catch (error) {}
});

const loginOpened = ref(false);

async function logout() {
  await omniaSdk.auth.user.logout();
  preview.value = false;
  location.reload();
}

type MenuItem = {
  title: string;
  href: string;
  exact?: boolean;
  hidden?: boolean;
  authenticated?: boolean;
};

const menus: MenuItem[] = [
  {
    title: "Home",
    href: "/",
    exact: true,
  },
  {
    title: "MyOmnia",
    href: "/myomnia",
    authenticated: true,
  },
  {
    title: "Servizio Quesiti",
    href: "/quesiti",
  },
  {
    title: "Memoweb",
    href: "/memoweb",
  },
  {
    title: "Scadenzario",
    href: "/scadenzario",
  },
  {
    title: "Riviste",
    href: "/riviste",
  },
  {
    title: "Formazione",
    href: "/#formazione",
    hidden: !useRuntimeConfig().public.formazioneUrl,
  },
  {
    title: "Tutorial",
    href: "/tutorial",
  },
].filter((item) => !item.hidden);

function isActive(item: MenuItem) {
  if (item.exact) {
    return route.path === item.href;
  }

  return route.path.startsWith(item.href);
}

const demoRequestOpened = ref<boolean>(false);
const sheetOpened = ref<boolean>(false);
</script>

<template>
  <div class="border-b print:hidden">
    <div class="main-content flex items-center py-0.5 gap-1">
      <Sheet v-model:open="sheetOpened">
        <SheetTrigger as-child>
          <Button variant="ghost" size="icon" class="shrink-0 md:hidden">
            <Icon name="gg:menu" class="h-5 w-5" />
            <span class="sr-only">Apri/chiudi menù di navigazione</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" class="flex flex-col">
          <ScrollArea>
            <nav class="grid font-medium gap-5">
              <NuxtLink @click="sheetOpened = false" to="/" class="h-10">
                <Logo color="hsl(var(--primary))" class="h-full" />
              </NuxtLink>
              <div>
                <div v-for="item in menus" :key="item.href">
                  <template v-if="!item.authenticated || (item.authenticated && loggedUser)">
                    <NuxtLink
                      @click="sheetOpened = false"
                      :to="item.href"
                      :class="['flex items-center gap-4 rounded-xl py-2 text-muted-foreground hover:text-primary', isActive(item) ? 'text-primary' : 'text-muted-foreground']"
                    >
                      {{ item.title }}
                    </NuxtLink>
                  </template>
                </div>
              </div>
            </nav>
          </ScrollArea>
        </SheetContent>
      </Sheet>

      <a href="https://gruppogaspari.it/" title="Sito Gruppo Gaspari" target="blank" class="text-base font-semibold hidden md:block">Gruppo Gaspari</a>

      <div class="flex-1"></div>

      <Button variant="ghost" class="ms-2 hidden lg:block" as-child>
        <NuxtLink href="/tutorial">Tutorial</NuxtLink>
      </Button>
      <template v-if="!loggedUser">
        <Button variant="ghost" @click="loginOpened = true">Accedi</Button>
        <Button variant="tertiary" class="ms-2" @click="demoRequestOpened = true">Richiedi Demo</Button>
      </template>
      <template v-else>
        <template v-if="userHasRole(loggedUser, ['SuperAdmin', 'Amministratore', 'Editore'])">
          <Label for="preview_mode">Anteprima</Label>
          <Switch id="preview_mode" v-model:checked="preview" @update:checked="refreshNuxtData()" />
        </template>
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="ghost" class="flex gap-1">
              <Icon name="carbon:user-avatar" class="h-6 w-6 text-primary" />
              <span class="sr-only">Apri/chiudi menù utente</span>
              <span class="hidden md:block">{{ loggedUser.full_name }}</span>
              <Icon name="carbon:chevron-down" class="h-3 w-3" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{{ loggedUser?.full_name || "..." }}</DropdownMenuLabel>
            <DropdownMenuItem @click="userSettingsOpened = true">Impostazioni utente</DropdownMenuItem>
            <DropdownMenuItem v-if="userHasRole(loggedUser, ['SuperAdmin', 'Amministratore', 'Editore', 'Ufficio clienti'])" as-child>
              <NuxtLink href="/admin">Pannello di amministrazione</NuxtLink>
            </DropdownMenuItem>
            <DropdownMenuItem v-if="userHasRole(loggedUser, ['Agente'])" as-child>
              <NuxtLink href="/clienti">Clienti</NuxtLink>
            </DropdownMenuItem>
            <DropdownMenuItem v-if="userHasRole(loggedUser, 'Consulente')"><NuxtLink href="/quesiti/assegnati">Quesiti assegnati a me</NuxtLink></DropdownMenuItem>
            <DropdownMenuSub v-if="userCustomers?.data.length">
              <DropdownMenuSubTrigger>Amministrazione</DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItem v-for="customer in userCustomers.data" :key="customer.id" as-child>
                    <NuxtLink :href="`/amministrazione/${customer.id}`">{{ customer.name }}</NuxtLink>
                  </DropdownMenuItem>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
            <DropdownMenuSeparator />
            <DropdownMenuItem @click="logout">Esci</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </template>
    </div>
  </div>

  <template v-if="loggedUser">
    <ClientOnly>
      <LazyFrontUserSettings :user="loggedUser" v-model:open="userSettingsOpened" @success="refreshUser(), (userSettingsOpened = false)" />
    </ClientOnly>

    <ClientOnly v-if="useRoute().path !== '/privacy' && useRoute().path !== '/note-legali'">
      <LazyFrontUserFirstLogin :user="loggedUser" v-model:open="userFirstLoginOpened" @success="refreshUser(), (userFirstLoginOpened = false)" />
    </ClientOnly>
  </template>

  <ClientOnly>
    <LazyFrontDemoRequest v-model:open="demoRequestOpened" />
  </ClientOnly>

  <header class="main-content">
    <div class="flex items-center justify-between pt-4 pb-3 print:p-0 print:pt-3">
      <h1 class="sr-only">Progetto Omnia</h1>
      <NuxtLink href="/" title="Homepage">
        <Logo class="h-10 lg:h-16 print:h-10" />
        <span class="sr-only">Logo Omnia</span>
      </NuxtLink>
      <div class="text-base hidden md:flex items-center">
        <span class="me-2">Seguici su:</span>
        <div
          class="flex items-center [&>a]:flex [&>a]:items-center [&>a]:justify-center [&>a]:bg-primary [&>a:hover]:bg-primary-600 [&>a]:text-white [&>a]:transition-colors [&>a]:rounded-full [&>a]:h-8 [&>a]:w-8 [&>a]:mx-1"
        >
          <a href="https://www.facebook.com/ProgettOmnia" title="Facebook" target="_blank" rel="noopener noreferrer">
            <Icon name="basil:facebook-solid" class="h-4 w-4" />
            <span class="sr-only">Facebook</span>
          </a>
          <a href="https://it.linkedin.com/showcase/progetto-omnia---gruppo-gaspari/" title="Linkedin" target="_blank" rel="noopener noreferrer">
            <Icon name="basil:linkedin-solid" class="h-4 w-4" />
            <span class="sr-only">Linkedin</span>
          </a>
          <a href="https://t.me/progettoomnia" title="Telegram" target="_blank" rel="noopener noreferrer">
            <Icon name="basil:telegram-solid" class="h-4 w-4" />
            <span class="sr-only">Telegram</span>
          </a>
          <!-- <a href="https://telegram" title="WhatsApp" target="_blank" rel="noopener noreferrer">
            <Icon name="basil:whatsapp-outline" class="h-4 w-4" />
            <span class="sr-only">WhatsApp</span>
          </a> -->
        </div>
      </div>
    </div>

    <nav class="mt-2 hidden md:flex items-center gap-2 print:hidden">
      <template v-for="item in menus" :key="item.href">
        <template v-if="!item.authenticated || (item.authenticated && loggedUser)">
          <NuxtLink :href="item.href" :class="['px-3 py-2 border-b-4', isActive(item) ? 'border-b-primary' : 'border-b-transparent']">
            {{ item.title }}
          </NuxtLink>
        </template>
      </template>
    </nav>
  </header>

  <div class="hidden md:block fixed left-0 top-[50%] -mt-[187px] z-20">
    <NuxtLink v-for="area in areas?.data" :href="link({ area })" class="text-white w-[180px] -ms-[152px] hover:ms-0 transition-all text-sm flex pe-5 pb-0.5 cursor-pointer">
      <div :class="`bg-${AREAS_MAP[area.id].color}`" class="flex items-center justify-end gap-2 w-full py-1 px-2 uppercase rounded-e">
        <span class="uppercase">{{ AREAS_MAP[area.id]?.title || area.title }}</span>
        <component class="w-6" active v-if="area.id in AREAS_MAP" :is="AREAS_MAP[area.id].icon" />
      </div>
    </NuxtLink>
  </div>

  <div class="print:min-h-0">
    <slot></slot>
  </div>

  <FrontBanner :icon="Tutorial" title="Impara ad utilizzare al meglio Progetto Omnia" cta-link="/tutorial" cta-text="Guarda i tutorial">
    <p>Guarda i nostri video tutorial: brevi video che guidano l'utente, passo passo, all'utilizzo delle varie funzionalità del portale.</p>
  </FrontBanner>

  <footer class="print:hidden">
    <div class="bg-primary-900 py-10">
      <div class="main-content text-white">
        <h2 class="mb-8 place-self-start">
          <NuxtLink href="/" title="Homepage">
            <Logo :colored="false" class="h-12"></Logo>
          </NuxtLink>
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-5">
          <div class="lg:col-span-2">
            <h3 class="font-bold text-xl pb-2 border-b border-white/50">Aree</h3>
            <div class="lg:columns-2 mt-3 [&>*]:block [&>*]:py-0.5 [&>*]:transition-colors [&>a:hover]:text-white/80">
              <NuxtLink v-for="area in areas?.data" :href="link({ area })">
                {{ area.title }}
              </NuxtLink>
            </div>
          </div>
          <div class="lg:col-span-2">
            <h3 class="font-bold text-xl pb-2 border-b border-white/50">Prodotti</h3>
            <div class="lg:columns-2 mt-3 [&>*]:block [&>*]:py-0.5 [&>*]:transition-colors [&>a:hover]:text-white/80">
              <a href="/memoweb">Memoweb</a>
              <a href="/quesiti">Servizio Quesiti</a>
              <a href="/scadenzario">Scadenzario</a>
              <a href="/riviste">Riviste</a>
              <a href="/notizie">Notizie</a>
            </div>
          </div>
          <div>
            <h3 class="font-bold text-xl pb-2 border-b border-white/50">Contatti</h3>
            <div class="mt-3 text-base [&>*]:block [&>*]:py-0.5 [&>*]:transition-colors [&>a:hover]:text-white/80">
              <span class="font-semibold">Grafiche E. Gaspari</span>
              <span>Via Minghetti 18 - 40057 Cadriano di Granarolo E. (BO)</span>
              <span>P. IVA 00089070403</span>
              <span>Progetto Omnia - ISSN 2532-8786</span>
              <a href="tel:051763200">Tel. 051 763200</a>
              <a href="mailto:info@progettoomnia.it">E-mail: info@progettoomnia.it</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-950 py-6">
      <div class="main-content text-white">
        <div class="flex flex-col md:flex-row md:items-center md:justify-between gap-y-3">
          <div class="flex flex-col md:flex-row gap-x-10 gap-y-3 text-sm [&>*]:block [&>*]:py-0.5 [&>*]:transition-colors [&>a:hover]:text-white/80">
            <NuxtLink href="/privacy">Privacy Policy</NuxtLink>
            <NuxtLink href="/note-legali">Note legali</NuxtLink>
            <!-- <NuxtLink href="/dichiarazione-accessibilita">Dichiarazione di accessibilità</NuxtLink> -->
            <NuxtLink href="/cookie">Cookie</NuxtLink>
          </div>
          <NuxtLink href="https://gruppogaspari.it/" title="Sito Gruppo Gaspari" target="blank" class="block text-sm py-0.5 hover:text-white/80 transition-colors">&#169; Gruppo Gaspari</NuxtLink>
        </div>
      </div>
    </div>
  </footer>

  <FrontLoginDialog v-model:opened="loginOpened" />

  <NuxtPwaManifest />
</template>

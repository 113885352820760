<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path
      d="M21.76.79h-9.03c-.89,0-1.61.72-1.61,1.61v3.65H3.22c-1.44,0-2.61,1.17-2.61,2.61v9.61c0,1.44,1.17,2.61,2.61,2.61h6.96v1.74h-2.68c-.28,0-.5.22-.5.5s.22.5.5.5h6.36c.28,0,.5-.22.5-.5s-.22-.5-.5-.5h-2.68v-1.74h6.95c1.44,0,2.61-1.17,2.61-2.61v-7.88h1.02c.89,0,1.61-.72,1.61-1.61V2.39c0-.89-.72-1.61-1.61-1.61ZM19.74,18.26c0,.89-.72,1.61-1.61,1.61H3.22c-.89,0-1.61-.72-1.61-1.61v-9.61c0-.89.72-1.61,1.61-1.61h7.9v1.73c0,.89.72,1.61,1.61,1.61h.94v2.06c0,.19.11.37.29.45.07.03.14.05.21.05.11,0,.23-.04.32-.12l2.93-2.44h2.31v7.88ZM22.37,8.78c0,.34-.27.61-.61.61h-4.51c-.12,0-.23.04-.32.12l-2.25,1.87v-1.49c0-.28-.22-.5-.5-.5h-1.44c-.33,0-.61-.27-.61-.61V2.39c0-.34.27-.61.61-.61h9.03c.33,0,.61.27.61.61v6.38Z"
    />
    <circle cx="14.63" cy="5.54" r=".66" />
    <path d="M17.25,4.88c-.36,0-.66.3-.66.66s.3.66.66.66.66-.3.66-.66-.3-.66-.66-.66Z" />
    <circle cx="19.86" cy="5.54" r=".66" />
    <polygon points="8.87 16.47 13.58 13.75 8.87 11.03 8.87 16.47" />
  </svg>
</template>
